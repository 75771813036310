<template>
  <div class="smz-home" v-if="$canAndRulesPass(user, 'home.see_in_menu')">

    <div class="smz-home__general" >
      <div>
        <div class="smz-home__general_name">Добро пожаловать, {{ user.first_name }} {{ user.middle_name }}!</div>
        <div class="smz-home__block-name" v-html="settings.general_block_desc"></div>
        <div class="smz-home__block-name">
          <i class="far fa-calendar-alt" style="font-size: 36px;margin-right: 10px"></i>
          {{ currentDate }}
        </div>
      </div>
      <div style="width: 200px">

        <div class="home-have-problem-text">Столкнулись с проблемой?</div>

        <SupportModal>
          <el-button
            size="small"
            plain
            type="primary"
            slot="reference"
            style="width: 80%; margin-top: 10px; margin-left: 20px"
            icon="far fa-question-circle"
          >
            Напишите здесь
          </el-button>
        </SupportModal>

      </div>
    </div>

    <el-divider></el-divider>

    <div class="smz-home__block" v-if="$canAndRulesPass(user, 'home.see_block_employee') || $canAndRulesPass(user, 'home.see_block_chief') ">
      <div class="smz-home__block-header">{{ settings.goals_block_name }}</div>
      <div class="smz-home__block-body">
        <div class="smz-home__block-body-buttons">
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_goals')"
            name="Карты целей"
            icon="fas fa-address-card"
            :to="{ name: 'Cards'}"
          ></HomeBlockButton>
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_successors')"
            name="Планы преемственности"
            icon="fas fa-user-check"
            :to="{ name: 'Successors'}"
          ></HomeBlockButton>
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_calibration')"
            name="Калибровка оценок"
            icon="fas fa-chart-line"
            :to="{ name: 'Calibration'}"
          ></HomeBlockButton>
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_evaluations')"
            name="Оценка кадрового потенциала"
            icon="fas fa-sort-amount-up"
            :to="{ name: 'HumanResourcesEvaluation'}"
          ></HomeBlockButton>
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_reports')"
            name="Отчеты"
            icon="fas fa-table"
            :to="{ name: 'Report'}"
          ></HomeBlockButton>
        </div>
        <div>
          <HomeSubBlockWithFiles
            v-if="$canAndRulesPass(user, 'home.see_block_employee')"
            :settings="settings"
            name="goals_employee"
          ></HomeSubBlockWithFiles>
          <HomeSubBlockWithFiles
            v-if="$canAndRulesPass(user, 'home.see_block_chief')"
            style="margin-top: 40px"
            :settings="settings"
            name="goals_chief"
          ></HomeSubBlockWithFiles>
        </div>
      </div>
    </div>


    <el-divider v-if="$canAndRulesPass(user, 'home.see_block_comp_manager')"></el-divider>

    <div class="smz-home__block" v-if="$canAndRulesPass(user, 'home.see_block_comp_manager')">
      <div class="smz-home__block-header">{{ settings.compensations_block_name }}</div>
      <div class="smz-home__block-body">
        <div class="smz-home__block-body-buttons">
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_comp_plans')"
            name="Планирование вознаграждений. Планы"
            icon="fas fa-hand-holding-usd"
            :to="{ name: 'CompensationProcedurePlans'}"
          ></HomeBlockButton>
          <HomeBlockButton
            v-if="$canAndRulesPass(user, 'home.see_button_comp_reports')"
            name="Планирование вознаграждений. Отчеты"
            icon="fas fa-table"
            :to="{ name: 'CompensationProcedureReport'}"
          ></HomeBlockButton>

        </div>
        <div>
          <HomeSubBlockWithFiles
            :settings="settings"
            name="compensations_managers"
          ></HomeSubBlockWithFiles>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {mapGetters} from "vuex";
import requestSender from "@/api/base/requestSender";
import HomeSubBlockWithFiles from "@/componentsCompany/smz/home/HomeSubBlockWithFiles.vue";
import HomeBlockButton from "@/componentsCompany/smz/home/HomeBlockButton.vue";
import CompensationProcedureCreateModal
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureCreateModal.vue";
import SupportModal from "@/componentsCompany/smz/home/SupportModal.vue";

export default {
  name: 'Home',
  components: {SupportModal, CompensationProcedureCreateModal, HomeBlockButton, HomeSubBlockWithFiles},

  data() {
    return {
      loading: false,
      settings: {},
    }
  },
  computed: {
    ...mapGetters(['user']),
    currentDate() {
      const date = new Date();
      const yyyy = date.getFullYear();
      let dd = date.getDate();
      if (dd < 10) dd = '0' + dd;
      const monthName = date.toLocaleString('ru-RU', {month: 'long'});

      return dd + ' ' + monthName + ' ' + yyyy + ' г.';
    }
  },
  mounted() {
    this.loadSettings();
  },
  methods: {
    loadSettings() {
      this.loading = true;

      requestSender('get', 'home/get-blocks-info')
        .then(data => {
          this.settings = data.settings;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}

</script>

<style lang="scss">
@import "@/assets/css/project-variables";
.smz-home {
  color: #141414 !important;

  .home-have-problem-text{
    margin-top: 36px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #145969;
  }

  &__general {
    display: flex;
    justify-content: space-between;

    &_name {
      font-weight: bold;
      font-size: 24px;
    }
  }

  &__block-body {
    display: flex;
    gap: 50px;
  }

  &__block-body-buttons {
    width: 680px;
    padding-top: 14px;

    a {
      text-decoration: none;
    }

    .el-card {
      margin-bottom: 20px;

      &__body{
        padding: 10px;
      }

      .card-icon {
        padding: 0 0 0 10px;
        font-size: 36px;
        opacity: 0.5;
      }

      .card-text {
        width: 100%;
        text-align: center;
      }

      .card-text.one-line {
        padding-top: 14px;
        text-transform: uppercase;
      }
    }

  }

  &__block-header {
    color: $--color-primary !important;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    padding: 10px 0 16px 0;
  }

  &__block-name {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
  }

  &__block-desc {
  }
}
</style>