export default {
    "sectionsBlockHeaderTemplate": "componentsCompany/smz/CardSectionsBlockHeader",
    "sectionsBlockFooterTemplate": "componentsCompany/smz/CardSectionsBlockFooter",
    "sections": {
        "required": {
            "showName": "Обязательные цели",
            "limitHeight": true,
            "description": "Обязательные цели - это стратегические цели, каскадированные руководством предприятия",
            "fromLibrary": "required",
            "addElementButtonText": "Создать цель",
            "emptyText": "Нет целей",
            "columnsConfig": "required_functional_columns",
            "fieldsAvailableForEdit": {
                "scale.weight_plan": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.period_result": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.period_fact": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.status": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.year_result": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.target_achieve_level": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.achieved_date": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "comment.intermediate_assessment.employee": {
                    "stages": [
                        "intermediate_assessment",
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisEmployeeCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.intermediate_assessment.chief": {
                    "stages": [
                        "intermediate_assessment",
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisChiefCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment.employee": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisEmployeeCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment.chief": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisChiefCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        },
        "functional": {
            "showName": "Функциональные цели",
            "description": "Функциональные цели - это цели, отражающие основные направления работы сотрудника",
            "limitHeight": true,
            "addElementButtonText": "Создать цель",
            "emptyText": "Нет целей",
            "columnsConfig": "required_functional_columns",
            "fieldsAvailableForEdit": {
                "scale.category": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "name": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.type": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.unit_id": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.target": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.deadline": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.weight_plan": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.period_result": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.period_fact": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.status": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.year_result": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.target_achieve_level": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.achieved_date": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "comment.intermediate_assessment.employee": {
                    "stages": [
                        "intermediate_assessment",
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisEmployeeCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.intermediate_assessment.chief": {
                    "stages": [
                        "intermediate_assessment",
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisChiefCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment.employee": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisEmployeeCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment.chief": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisChiefCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        },
        "career": {
            "showName": "Карьерная цель",
            "description": "Сформулируйте ваше карьерное устремление в перспективе 1-3 года",
            "limitHeight": true,
            "addElementButtonText": "Создать цель",
            "emptyText": "Нет целей",
            "columnsConfig": "career_columns",
            "fieldsAvailableForEdit": {
                "desc": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                }
            }
        },
        "growth": {
            "showName": "Цели развития",
            "description": "Цели развития должны быть направлены на развитие вашего профессионализма и/или поддержку вашей Карьерной цели и/или Компетенций",
            "limitHeight": true,
            "addElementButtonText": "Создать цель",
            "emptyText": "Нет целей",
            "columnsConfig": "growth_columns",
            "fieldsAvailableForEdit": {
                "name": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "desc": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.growth_direction": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.criteria": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.deadline": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.period_result": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.status": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.year_result": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.target_achieve_level": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "scale.achieved_date": {
                    "stages": [
                        "plan",
                        "intermediate_assessment",
                        "assessment"
                    ]
                },
                "comment.intermediate_assessment.employee": {
                    "stages": [
                        "intermediate_assessment",
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisEmployeeCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.intermediate_assessment.chief": {
                    "stages": [
                        "intermediate_assessment",
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisChiefCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment.employee": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisEmployeeCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                },
                "comment.assessment.chief": {
                    "stages": [
                        "assessment"
                    ],
                    "rules": [
                        "app\\build\\smz\\rbac\\businessRules\\ElementCommentThisChiefCardRule"
                    ],
                    "skipElementEditPermissionsCheck": true
                }
            }
        }
    },
    "required_functional_columns": [
        {
            "label": "№",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "width": 37,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementOrderTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Категория целей",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "if": "templateData.card.info.format !== 1",
            "width": 160,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementCategoryTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Название цели",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "minWidth": 200,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementNameNoDescTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Описание цели",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "minWidth": 300,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementDescTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Тип цели",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "width": 160,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementScaleTypeTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Единица измерения",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "width": 100,
            "template": "components/tableColumnTemplates/card/ElementLibraryUnitTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Целевой показатель",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "minWidth": 100,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementTargetTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Целевой срок достижения цели",
            "labelClassName": "card-column-color-1",
            "width": 120,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementDeadlineTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Вес цели, план, %",
            "labelClassName": "card-column-color-1",
            "if": "templateData.card.info.format !== 1",
            "width": 90,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementWeightPlanTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Результат за период",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "minWidth": 200,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementPeriodResultTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "% выполнения, факт за период",
            "labelClassName": "card-column-color-2",
            "if": "templateData.card.info.format !== 1 && templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "width": 130,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementPeriodFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Статус цели",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "width": 110,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementStatusTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий Сотрудника о результатах за период",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "width": 180,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'intermediate_assessment.employee'",
                "last-comment": "scope.row.last_comment_by_stage['intermediate_assessment.employee']",
                "comments-count": "scope.row.comments_count_by_stage['intermediate_assessment.employee']"
            }
        },
        {
            "label": "Комментарий Руководителя о результатах за период",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "width": 180,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'intermediate_assessment.chief'",
                "last-comment": "scope.row.last_comment_by_stage['intermediate_assessment.chief']",
                "comments-count": "scope.row.comments_count_by_stage['intermediate_assessment.chief']"
            }
        },
        {
            "label": "Результат за год",
            "labelClassName": "card-column-color-3",
            "minWidth": 200,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementYearResultTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Уровень достижения цели",
            "labelClassName": "card-column-color-3",
            "width": 110,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementTargetAchieveLevelTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "% выполнения, факт",
            "labelClassName": "card-column-color-3",
            "width": 116,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementFactTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Фактический срок достижения цели",
            "labelClassName": "card-column-color-3",
            "width": 150,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementAchievedDateTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Вес цели, факт, %",
            "labelClassName": "card-column-color-3",
            "if": "templateData.card.info.format !== 1",
            "width": 80,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "prop": "scale.weight_fact"
        },
        {
            "label": "Комментарий Сотрудника о результатах за год",
            "labelClassName": "card-column-color-3",
            "width": 180,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment.employee'",
                "last-comment": "scope.row.last_comment_by_stage['assessment.employee']",
                "comments-count": "scope.row.comments_count_by_stage['assessment.employee']"
            }
        },
        {
            "label": "Комментарий Руководителя о результатах за год",
            "labelClassName": "card-column-color-3",
            "width": 180,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment.chief'",
                "last-comment": "scope.row.last_comment_by_stage['assessment.chief']",
                "comments-count": "scope.row.comments_count_by_stage['assessment.chief']"
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "career_columns": [
        {
            "label": "№",
            "labelClassName": "card-column-color-1",
            "width": 37,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementOrderTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Описание цели",
            "labelClassName": "card-column-color-1",
            "minWidth": 200,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementDescTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ],
    "growth_columns": [
        {
            "label": "№",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "width": 37,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementOrderTableColumn",
            "bind": {
                "scope": "scope"
            }
        },
        {
            "label": "Направление развития",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "minWidth": 200,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementGrowthDirectionTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Описание цели",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "minWidth": 300,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementDescTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Критерий достижения цели",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "minWidth": 200,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementCriteriaTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            }
        },
        {
            "label": "Целевой срок достижения цели",
            "labelClassName": "card-column-color-1",
            "fixed": true,
            "width": 120,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementDeadlineTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Результат за период",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "minWidth": 200,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementPeriodResultGrowthTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Статус цели",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "width": 110,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementStatusTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий Сотрудника о результатах за период",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "width": 180,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'intermediate_assessment.employee'",
                "last-comment": "scope.row.last_comment_by_stage['intermediate_assessment.employee']",
                "comments-count": "scope.row.comments_count_by_stage['intermediate_assessment.employee']"
            }
        },
        {
            "label": "Комментарий Руководителя о результатах за период",
            "if": "templateData.stages.find(s => s.type === \"intermediate_assessment\")",
            "labelClassName": "card-column-color-2",
            "width": 180,
            "showOnStages": [
                "intermediate_assessment",
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'intermediate_assessment.chief'",
                "last-comment": "scope.row.last_comment_by_stage['intermediate_assessment.chief']",
                "comments-count": "scope.row.comments_count_by_stage['intermediate_assessment.chief']"
            }
        },
        {
            "label": "Результат за год",
            "labelClassName": "card-column-color-3",
            "minWidth": 200,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementYearResultGrowthTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Уровень достижения цели",
            "labelClassName": "card-column-color-3",
            "width": 110,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementTargetAchieveLevelGrowthTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Фактический срок достижения цели",
            "labelClassName": "card-column-color-3",
            "width": 150,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementAchievedDateTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged(); }"
            }
        },
        {
            "label": "Комментарий Сотрудника о результатах за год",
            "labelClassName": "card-column-color-3",
            "width": 180,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment.employee'",
                "last-comment": "scope.row.last_comment_by_stage['assessment.employee']",
                "comments-count": "scope.row.comments_count_by_stage['assessment.employee']"
            }
        },
        {
            "label": "Комментарий Руководителя о результатах за год",
            "labelClassName": "card-column-color-3",
            "width": 180,
            "showOnStages": [
                "assessment"
            ],
            "showOnCompleted": true,
            "template": "componentsCompany/smz/tableColumnTemplates/card/ElementSmzCommentsTableColumn",
            "bind": {
                "scope": "scope",
                "stage": "'assessment.chief'",
                "last-comment": "scope.row.last_comment_by_stage['assessment.chief']",
                "comments-count": "scope.row.comments_count_by_stage['assessment.chief']"
            }
        },
        {
            "label": "",
            "template": "components/tableColumnTemplates/card/ElementActionsTableColumn",
            "bind": {
                "scope": "scope"
            },
            "on": {
                "elements-changed": "() => { templateData.elementsChanged() }"
            },
            "align": "'center'",
            "width": 50
        }
    ]
}