export default {
    "default_number_format": {
        "decimal_point": ",",
        "decimal_length": 2
    },
    "menu_template": "componentsCompany/smz/header/MenuComponent",
    "enums": {
        "evaluation_types": {
            "TYPE_EFFICIENCY": 1,
            "TYPE_COMPETENCE": 2,
            "TYPE_POTENTIAL": 3,
            "TYPE_FINAL_EFFICIENCY": 4,
            "TYPE_RISK_DISMISSAL": 5,
            "TYPE_IMPACT_BUSINESS": 6,
            "TYPE_TOTAL_COMPETENCE": 7
        },
        "evaluation_headers": {
            "1": "Оценка эффективности деятельности",
            "2": "Оценка компетенций",
            "3": "Потенциал",
            "4": "Итоговая оценка эффективности деятельности",
            "5": "Риск увольнения",
            "6": "Влияние на бизнес",
            "7": "Общая оценка компетенций"
        },
        "evaluation_total_evaluations": {
            "TOTAL_EVALUATE_EFFICIENCY": "evaluate_efficiency_id",
            "TOTAL_EVALUATE_COMPETENCE": "evaluate_competence_id",
            "TOTAL_EVALUATE_FINAL_EFFICIENCY": "evaluate_final_efficiency_id",
            "TOTAL_EVALUATE_POTENTIAL": "evaluate_potential_id",
            "TOTAL_EVALUATE_RISK_DISMISSAL": "evaluate_risk_dismissal_id",
            "TOTAL_EVALUATE_IMPACT_ON_BUSINESS": "evaluate_impact_on_business_id"
        },
        "successor_position_risks": {
            "3": "Высокий",
            "2": "Средний",
            "1": "Низкий"
        },
        "successor_ready_levels": {
            "1": "Готов сейчас",
            "2": "Готов через 1-3 года",
            "3": "Готов через 3-6 лет",
            "4": "Оперативный резерв"
        },
        "compensation_procedure_types": {
            "raise_rss": "Повышение заработной платы (РСС)",
            "raise_workers": "Повышение заработной платы (Рабочие)",
            "year_bonus": "Годовой бонус"
        },
        "compensation_procedure_statuses": {
            "1": "В работе",
            "2": "Завершена"
        },
        "compensation_procedure_rss_agree_statuses": {
            "1": "Не отправлено на согласование",
            "2": "Возращено на доработку",
            "3": "Отправлено на согласование",
            "4": "Согласовано"
        }
    }
}