export default {
    "deputies_page_columns": [
        {
            "label": "Личный номер Заместителя",
            "prop": "deputyUser.persnum",
            "width": 240
        },
        {
            "label": "Заместитель",
            "prop": "deputyUser.full_name"
        },
        {
            "label": "Личный номер Делегатора",
            "prop": "deputyForUser.persnum",
            "width": 240
        },
        {
            "label": "Делегатор",
            "prop": "deputyForUser.full_name"
        },
        {
            "label": "Дата начала",
            "prop": "date_start",
            "width": 120
        },
        {
            "label": "Дата окончания",
            "prop": "date_end",
            "width": 150
        }
    ]
}