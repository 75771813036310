export default {
    "export": {
        "library_folder": {
            "class": "app\\build\\smz\\exportImport\\services\\FolderExportService",
            "permissions": []
        },
        "card": {
            "class": "app\\build\\smz\\exportImport\\services\\CardExportService",
            "permissions": []
        },
        "calibration_table": {
            "class": "app\\build\\smz\\exportImport\\services\\CalibrationTableExportService",
            "permissions": []
        },
        "successors": {
            "class": "app\\build\\smz\\exportImport\\services\\SuccessorsExportService",
            "permissions": []
        },
        "raise_rss_users": {
            "class": "app\\build\\smz\\exportImport\\services\\RaiseRssUsersExportService",
            "permissions": []
        },
        "raise_workers_users": {
            "class": "app\\build\\smz\\exportImport\\services\\RaiseWorkersUsersExportService",
            "permissions": []
        },
        "home_files": {
            "class": "app\\build\\smz\\exportImport\\services\\HomeFilesExportService",
            "permissions": []
        },
        "comp_plan": {
            "class": "app\\build\\smz\\exportImport\\services\\CompPlanExportService",
            "permissions": []
        }
    },
    "import": {
        "home_files": {
            "class": "app\\build\\smz\\exportImport\\services\\HomeFilesImportService",
            "permissions": []
        },
        "raise_rss_ranges_by_bands": {
            "class": "app\\build\\smz\\exportImport\\services\\CompensationProcedureRaiseRssRangesByBandsImportService",
            "permissions": [],
            "example": "RangesByBands.xlsx"
        },
        "raise_workers_ranges_by_bands": {
            "class": "app\\build\\smz\\exportImport\\services\\CompensationProcedureRaiseWorkersRangesByBandsImportService",
            "permissions": [],
            "example": "RangesByBands.xlsx"
        }
    }
}